@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://unpkg.com/@tailwindcss/typography@0.2.x/dist/typography.min.css");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poiret+One&display=swap");

* {
  padding: 0;
  margin: 0;
  z-index: 10;
}
html {
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
}
.prose a {
  color: #f7fff7ff;
  text-decoration: underline dotted;
  text-decoration-color: #ffe66dff;
  text-underline-offset: 3px;
  font-weight: bold;
}
.prose a:hover {
  text-decoration-color: #ff6b6bff;
}
